<script setup lang="ts">
import type { HourRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ openingHours: HourRecord[] }>();

const { locale } = useI18n();

function getWeekdayLocale(localeName: string, weekDay: any) {
  const format = new Intl.DateTimeFormat(localeName, { weekday: weekDay }).format;
  return [...Array(7).keys()]
    .map(day => format(new Date(Date.UTC(2021, 1, day + 1))));
}

const openingHourGroups = computed(() => {
  const objectList = props.openingHours.reduce((r: any, a: any) => {
    r[a.dayOfWeek] = r[a.dayOfWeek] || [];
    r[a.dayOfWeek].push(a);
    return r;
  }, Object.create(null));

  return Object.entries(objectList);
});
</script>

<template>
  <div>
    <div v-for="openingHourGroup in openingHourGroups" :key="openingHourGroup[0]" class="mb-sm flex flex-col md:flex-row md:justify-between">
      <div>{{ getWeekdayLocale(locale, "long")[parseInt(openingHourGroup[0]) - 1] }}</div>
      <div>
        <span v-for="(openingHour, itemIndex) in (openingHourGroup[1] as HourRecord[])" :key="openingHour.id" class="text-sm font-bold">
          <span v-if="openingHour.startHour">{{ openingHour.startHour }} - {{ openingHour.endHour }}<span v-if="itemIndex === 0 && (openingHourGroup[1] as HourRecord[]).length > 1">&nbsp;&&nbsp;</span></span>
          <span v-if="!openingHour.startHour">{{ $t('store.label.opening-hours.closed') }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
